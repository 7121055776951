import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import ExpandableAppBar from './components/MenuAndBar/ExpandableAppBar';
import MuiXLicense from './services/MuiXLicense';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
    return (
        <Router>
            <MuiXLicense />
            <ExpandableAppBar />
            <Routes />
        </Router>
    );
}

export default App;


import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from "jwt-decode";

const api = axios.create({
    //baseURL: 'https://poincare.mathstat.uottawa.ca/api',
    //baseURL: 'https://christophebw.net/api',
    baseURL: 'https://demo.christophebw.net:2053/api',
    //baseURL: 'http://localhost:22223/api',
});

function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
}

const logout = () => {
    Cookies.remove('userUid');
    Cookies.remove('userToken');
    Cookies.remove('userRole');
    Cookies.remove('userEmail');
    Cookies.remove('userTagAccess');
    window.location.href = '/login'; // Redirect to the login page
};

api.interceptors.request.use((config) => {
    const token = Cookies.get('userToken');

    if (token && isTokenExpired(token)) {
        logout();
        return Promise.reject(new Error('Token has expired'));
    }

    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => {
        // If the response is successful, just return it
        return response;
    },
    (error) => {
        // If the response has a 401 status code, log out the user
        if (error.response && error.response.status === 401) {
            logout(); // Call the logout function defined earlier
        }

        // Otherwise, return the error to be handled elsewhere
        return Promise.reject(error);
    }
);

export default api;

import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, TextField, Typography, LinearProgress } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'services/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import api from 'services/axiosApi';

const SignupPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [touchedFields, setTouchedFields] = useState({
        email: false,
        password: false,
        passwordCheck: false
    });

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const isMediumSecure = (pass) => {
        return /[a-z]/.test(pass) && /[A-Z]/.test(pass) && /\d/.test(pass) && pass.length >= 8;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                api.post('/users/new-user', { uid: user.uid })
                    .then(() => {
                        setSuccess('Signup Successful');
                        setIsLoading(false);
                        navigate('/login', { state: { email } });
                    })
                    .catch(apiError => {
                        setIsLoading(false);
                        setError(apiError.message);
                    });
            })
            .catch((firebaseError) => {
                setIsLoading(false);
                setError(firebaseError.message);
            });
    };

    const handleBlur = (field) => {
        setTouchedFields({
            ...touchedFields,
            [field]: true
        });
    };

    return (
        <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
            <Paper style={{ padding: '1rem' }}>
                {isLoading && <LinearProgress />}
                <Typography variant="h4" align="center">
                    Signup
                </Typography>
                {success && (
                    <Typography variant="body2" color="success">
                        {success}
                    </Typography>
                )}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => handleBlur('email')}
                        error={touchedFields.email && !isValidEmail(email)}
                        helperText={touchedFields.email && !isValidEmail(email) ? 'Invalid email format' : ''}
                        disabled={isLoading}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => handleBlur('password')}
                        error={touchedFields.password && !isMediumSecure(password)}
                        helperText={touchedFields.password && !isMediumSecure(password) ? (
                            <ul>
                                <li>Password should be at least 8 characters long.</li>
                                <li>Include at least one lowercase letter.</li>
                                <li>Include at least one uppercase letter.</li>
                                <li>Include at least one number.</li>
                            </ul>
                        ) : ''}
                        disabled={isLoading}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={passwordCheck}
                        onChange={(e) => setPasswordCheck(e.target.value)}
                        onBlur={() => handleBlur('passwordCheck')}
                        error={touchedFields.passwordCheck && password !== passwordCheck}
                        helperText={touchedFields.passwordCheck && password !== passwordCheck ? 'Passwords do not match' : ''}
                        disabled={isLoading}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>
                                Signup
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => navigate('/login')}
                                fullWidth
                                disabled={isLoading}
                            >
                                Go to Login
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => navigate('/')}
                                fullWidth
                                disabled={isLoading}
                            >
                                Go to Home
                            </Button>
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                </form>
            </Paper>
        </Grid>
    );
};

export default SignupPage;

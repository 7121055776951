import React, {useEffect, useState} from 'react';
import api from "services/axiosApi";
import logo from 'asset/logo.png';
import {Box, Grid, Button, TextField, LinearProgress} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import {transformedTerm, getLanguages, getTags} from "utils/DataGetter";
import TermCardsRender from "components/Term/TermCardsRender";

const SearchPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [languages, setLanguages] = useState([])
    const [tags, setTags] = useState([])
    const [terms, setTerms] = useState([]);

    async function fetchData() {
        setIsLoading(true);
        const languages = await getLanguages();
        const tags = await getTags();
        setLanguages(languages);
        setTags(tags);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    async function handleSearch() {
        const query = searchQuery.trim();
        if (query.length === 0) {
            return;
        }

        setIsLoading(true);

        await api.get(`/terms/search/`, {
            params: {
                query: query,
            }
        }).then((response) => {
            const uniqueTerms = [...new Map(response.data.map(item => [item.id, item])).values()]; // Remove duplicates based on term id
            const transformedTerms = uniqueTerms.map(term => transformedTerm(term, languages, tags));
            console.log("transformedTerms: ", transformedTerms);
            setTerms(transformedTerms);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }


    return (
        <Box style={{ margin: '4rem' }}>
            <img src={logo} alt="Logo" style={{ display: 'block', margin: '0 auto', marginBottom: '2rem', width: '30%' }} />

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={11}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search for a term"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />

                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        startIcon={<SearchIcon />}
                        size="large"
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress style={{visibility: isLoading ? 'visible' : 'hidden'}}/>
                </Grid>
                <Grid item xs={12}>
                    <TermCardsRender transformedTerms={terms} hideButton={false} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default SearchPage;

import React, {useEffect, useState} from 'react';
import {
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";

export default function TermDialog(props) {
    const {term, isOpen, setIsOpen} = props;
    const [termId, setTermId] = useState(null); // number
    const [termTranslations, setTermTranslations] = useState([]); //
    const [termTags, setTermTags] = useState([]);
    const [termNotes, setTermNotes] = useState([]);

    useEffect(() => {
        console.log("term: ", term);
        if (term === null) {
            setIsOpen(false);
            return;
        }
        setTermId(term.id);
        setTermTranslations(term.translations);
        setTermTags(term.tags);
        setTermNotes(term.notes);
    }, [term, setIsOpen]);

    function handleCancel() {
        setIsOpen(false);
    }

    return (<Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth='sm'>
            <DialogTitle>Term Card</DialogTitle>
            <DialogContent>
                <Typography variant="button" color="text.secondary">
                    Term ID: {termId}
                    <br/>
                </Typography>
                {termTags.map((tag, index) => (<Chip
                        key={index}
                        label={tag}
                        color="primary"
                        size="small"
                        sx={{marginRight: 1}}
                    />))}
                <Card
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                    }}>
                    <Table>
                        <TableBody>
                            {termTranslations.map((translation, index) => (<TableRow key={index}>
                                    <TableCell>
                                        <Typography variant="button">
                                            {translation.label}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {translation.translation}
                                        </Typography>
                                    </TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </Card>
                <Typography variant="body1">
                    <b>Notes: </b>{termNotes}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Close</Button>
            </DialogActions>
        </Dialog>);
}
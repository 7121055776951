import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TableViewIcon from '@mui/icons-material/TableView';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import TagIcon from '@mui/icons-material/Tag';
import PeopleIcon from '@mui/icons-material/People';
import {Chip, ListItemButton} from "@mui/material";
import Cookies from "js-cookie";

const MenuDrawer = ({isOpen, toggleDrawer}) => {
    const userRole = Cookies.get('userRole');

    const list = () => (<div
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
        >
            <Typography variant="h5" style={{padding: '16px'}}>Math Term Bank</Typography>
            <Typography variant="subtitle1" style={{paddingLeft: '16px'}}>University of Ottawa</Typography>
            <Divider style={{marginTop: '1rem'}}/>
            <List>
                <ListItemButton to="/search">
                    <ListItemIcon><SearchIcon/></ListItemIcon>
                    <ListItemText primary="Search"/>
                </ListItemButton>
                <ListItemButton to="/feedback">
                    <ListItemIcon><FeedbackIcon/></ListItemIcon>
                    <ListItemText primary="Feedback"/>
                </ListItemButton>
                <ListItemButton to="/about" disabled={true}>
                    <ListItemIcon><InfoIcon/></ListItemIcon>
                    <ListItemText primary="About"/>
                </ListItemButton>
            </List>
            {(userRole === 'admin' || userRole === 'editor') && <>
                <Divider style={{marginTop: '1rem'}}>
                    <Chip label="Editor" color="primary"/>
                </Divider>
                <List>
                    <ListItemButton to="/table">
                        <ListItemIcon><TableViewIcon/></ListItemIcon>
                        <ListItemText primary="Table"/>
                    </ListItemButton>
                </List>
            </>
            }
            {userRole === 'admin' && <>
                <Divider style={{marginTop: '1rem'}}>
                    <Chip label="Admin" color="warning"/>
                </Divider>
                <List>
                    <ListItemButton to="/management/table">
                        <ListItemIcon><TagIcon/></ListItemIcon>
                        <ListItemText primary="Table Management"/>
                    </ListItemButton>
                    <ListItemButton to="/management/users">
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText primary="Users Management"/>
                    </ListItemButton>
                </List>
            </>}
        </div>);

    return (<Drawer anchor="left" open={isOpen} onClose={() => toggleDrawer(false)}>
            {list()}
        </Drawer>);
};

export default MenuDrawer;

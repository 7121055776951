import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDHI8VNr2kLCA-MWB876cyfinPTJbTl06Q",
    authDomain: "math-term-dict.firebaseapp.com",
    projectId: "math-term-dict",
    storageBucket: "math-term-dict.appspot.com",
    messagingSenderId: "974600994168",
    appId: "1:974600994168:web:3ece907a2cc54812c15c8d",
    measurementId: "G-WNHH9V47XB"
};

initializeApp(firebaseConfig);
const auth = getAuth();

export { auth };

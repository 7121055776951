import React from 'react';
import api from 'services/axiosApi';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    LinearProgress,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import {getTransformedTermById} from "utils/DataGetter";
import TermCardsRender from "components/Term/TermCardsRender";

import DeleteIcon from '@mui/icons-material/Delete';

export default function FeedbackDetail(props) {
    // Props
    const {selectedFeedbackId, feedbackList, fetchData, userRole, userUid} = props;

    // data
    const selectedFeedback = feedbackList.find(feedback => feedback.id === selectedFeedbackId);
    const [replyList, setReplyList] = React.useState([]);
    const [transformedTerms, setTransformedTerms] = React.useState([]);

    // states
    const [isFetching, setIsFetching] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isReplying, setIsReplying] = React.useState(false);


    // if user is the poster, or user has role admin or editor
    const [isAllowedActions, setIsAllowedActions] = React.useState(false);

    // color by status
    const statusColor = {
        open: 'primary', solved: 'success', closed: 'secondary',
    }

    async function getTermAndReplies() {
        if (!selectedFeedback) {
            return;
        }

        setIsFetching(true);

        if (selectedFeedback.term_id !== null) {
            const transformedTerm = await getTransformedTermById(selectedFeedback.term_id);
            setTransformedTerms([transformedTerm]);
        } else {
            setTransformedTerms([]);
        }

        setIsAllowedActions(selectedFeedback.user_id === userUid || userRole === 'admin' || userRole === 'editor');
        const response = await api.get('/feedback/' + selectedFeedback.id + '/replies');
        setReplyList(response.data);

        setIsFetching(false);
    }

    React.useEffect(() => {
        getTermAndReplies();
    }, [selectedFeedbackId]);

    if (!selectedFeedback) {
        return <Alert severity="info">Please select a feedback from the left to view.</Alert>;
    }

    async function handleReply() {
        setIsReplying(true);
        const reply = document.getElementById('reply').value;
        try {
            const response = await api.post('/feedback/' + selectedFeedback.id + '/replies', {
                content: reply
            });

            // Clear the reply box
            document.getElementById('reply').value = '';
        } catch (e) {
            console.log(e);
        } finally {
            setIsReplying(false);
            getTermAndReplies();
        }
    }

    function toMultiline(str) {
        return str.split('\n').map((line, index, array) => (<>
            {line}
            {index === array.length - 1 ? null : <br/>}
        </>));
    }

    function updateStatus(status) {
        setIsUpdating(true);
        api.put('/feedback/' + selectedFeedback.id + '/status', {
            status: status
        }).then(() => {
            fetchData();
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setIsUpdating(false);
        });
    }

    function deleteFeedback() {
        setIsUpdating(true);
        api.delete('/feedback/' + selectedFeedback.id).then(() => {
            fetchData();
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setIsUpdating(false);
        });
    }

    return (<Box padding={2}>
        {isFetching ? (
            <Box sx={{width: '100%', overflowWrap: 'break-word'}}>
                <LinearProgress/>
                <Card>
                    <CardContent>
                        <Skeleton animation="wave" variant="text" width={300} sx={{ fontSize: '2rem' }} />
                        <Skeleton animation="wave" variant="text" width={100} sx={{ fontSize: '1rem' }} />
                        <Skeleton animation="wave" variant="text" width={200} sx={{ fontSize: '1rem' }} />
                        <Skeleton animation="wave" variant="text" width={400} sx={{ fontSize: '1rem' }} />
                        <Divider style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}/>
                    </CardContent>
                    <Skeleton animation="wave" variant="rectangular" height={300} />

                </Card>
            </Box>
        ) : (
        <Box sx={{width: '100%', overflowWrap: 'break-word'}}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom style={{overflowWrap: 'break-word'}}>
                        {selectedFeedback.title}
                    </Typography>
                    <Box display="flex" alignItems="center" style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}>
                        <Typography variant="subtitle2" color='text.secondary' display='inline'
                                    style={{marginRight: '0.2rem'}}>
                            Status:
                        </Typography>
                        <Chip label={selectedFeedback.status} color={statusColor[selectedFeedback.status]} size='small'
                              style={{marginRight: '1rem'}}/>
                    </Box>
                    <Box display="flex" flexWrap="wrap" alignItems="center">
                        <Typography variant="subtitle2" color='text.secondary' display='inline'
                                    style={{marginRight: '0.2rem'}}>
                            Tags:
                        </Typography>
                        {selectedFeedback.tags.length > 0 ? (selectedFeedback.tags.map((tag, index) => (<Chip
                            key={index}
                            label={tag}
                            color="warning"
                            size='small'
                            style={{marginRight: '0.2rem'}}
                        />))) : (<Chip label="No Tags" color="warning" disabled={true} size='small'/>)}
                    </Box>
                    <Box display="flex" alignItems="center" style={{marginTop: '0.5rem'}}>
                        <Typography variant="subtitle2" color='primary' display="inline">
                            {selectedFeedback.userEmail}
                        </Typography>
                        <Typography variant="subtitle2" color='text.secondary' display='inline'
                                    style={{marginLeft: '1rem'}}>
                            posted on {new Date(selectedFeedback.created_at).toLocaleDateString()} at{' '}
                            {new Date(selectedFeedback.created_at).toLocaleTimeString([], {
                                hour: '2-digit', minute: '2-digit'
                            })}
                        </Typography>
                    </Box>

                    <Divider style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}/>

                    <Typography variant="subtitle2"
                                style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
                                hidden={selectedFeedback.term_id === null}
                    >
                        Related Term <b>(ID: {selectedFeedback.term_id})</b>:
                    </Typography>

                    <TermCardsRender transformedTerms={transformedTerms} hideButton={true}/>

                    <Typography variant="button"
                                style={{marginTop: '0.5rem'}}>
                        Description:
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{overflowWrap: 'break-word'}}>
                        {toMultiline(selectedFeedback.content)}
                    </Typography>
                    <LinearProgress style={{visibility: isUpdating ? 'visible' : 'hidden'}}/>
                </CardContent>

                {/* Only show the buttons if user is the poster or user has role admin or editor */}
                {isAllowedActions && <CardActions>
                    <Button variant='contained' size="small" color="primary"
                            disabled={isUpdating || selectedFeedback.status === 'open'}
                            onClick={() => updateStatus('open')}>
                        Mark as Open
                    </Button>
                    <Button variant='contained' size="small" color="success"
                            disabled={isUpdating || selectedFeedback.status === 'solved'}
                            onClick={() => updateStatus('solved')}>
                        Mark as Solved
                    </Button>
                    <Button variant='contained' size="small" color="secondary"
                            disabled={isUpdating || selectedFeedback.status === 'closed'}
                            onClick={() => updateStatus('closed')}>
                        Mark as Closed
                    </Button>
                    <Button variant='contained' size="small" color="error"
                            disabled={isUpdating}
                            startIcon={<DeleteIcon/>}
                            onClick={() => deleteFeedback()}>
                        Delete
                    </Button>
                </CardActions>}
            </Card>

            <Alert severity="info" sx={{marginY: '1rem'}}>
                Replies:
            </Alert>

            {replyList.map((reply, index) => (<Card key={index} sx={{marginBottom: '1rem'}}>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2" color='primary' display="inline">
                            {reply.userEmail}
                        </Typography>
                        <Typography variant="subtitle2" color='text.secondary' display='inline'
                                    style={{marginLeft: '1rem'}}>
                            commented
                            on {new Date(reply.created_at).toLocaleDateString()} at {new Date(reply.created_at).toLocaleTimeString([], {
                            hour: '2-digit', minute: '2-digit'
                        })}
                        </Typography>
                    </Box>
                    <Divider style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}/>
                    <Typography variant="body2" style={{overflowWrap: 'break-word'}}>
                        {toMultiline(reply.content)}
                    </Typography>

                </CardContent>
            </Card>))}

            <Card sx={{marginTop: '1rem'}}>
                <CardContent>
                    <Typography variant="subtitle2" display='inline'>
                        Reply to this feedback:
                    </Typography>
                    {isReplying ? <LinearProgress style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}/> :
                        <Divider style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}/>}
                    <Typography variant="body2" style={{overflowWrap: 'break-word'}}>
                        <TextField id="reply" label='Reply' multiline rows={4} style={{width: '100%'}}
                                   disabled={isReplying}/>
                    </Typography>

                    <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                        <Button variant="contained" color="primary" onClick={handleReply} disabled={isReplying}>
                            Reply
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        )}
    </Box>);
}

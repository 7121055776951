import React from 'react';
import {Box, Chip, ListItem, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {FixedSizeList} from 'react-window';
import Typography from "@mui/material/Typography";
import orange from "@mui/material/colors/orange";

export default function FeedbackList(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const {setSelectedFeedbackId, feedbackList, searchText, statusFilter, isOnlyDisplayMyFeedback, userUid} = props;

    const filteredFeedbackList = feedbackList.filter((feedback) => {
        return ((statusFilter[feedback.status])
            && (!isOnlyDisplayMyFeedback || feedback.user_id === userUid)
            && (feedback.title.toLowerCase().includes(searchText.toLowerCase())
                || feedback.content.toLowerCase().includes(searchText.toLowerCase())));

    });

    const handleListItemClick = (event, feedbackId) => {
        setSelectedFeedbackId(feedbackId);
    };

    const textStyle = {
        overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%',
    };

    // color by status
    const statusColor = {
        open: 'primary', solved: 'success', closed: 'secondary',
    }

    return (<Box>
        <FixedSizeList height={800} width='100%' itemSize={100} itemCount={filteredFeedbackList.length}>
            {({index, style}) => (<ListItem style={style} key={index} disablePadding>
                <ListItemButton
                    selected={index === selectedIndex}
                    onClick={(event) => handleListItemClick(event, filteredFeedbackList[index].id)}
                >
                    <ListItemText
                        primary={<Box display="flex" alignItems="center">
                            {filteredFeedbackList[index].user_id === userUid &&
                                <Chip label="I posted" color="error" size="small"
                                      style={{marginRight: '0.5rem'}}/>}
                            <Chip label={filteredFeedbackList[index].status}
                                  color={statusColor[filteredFeedbackList[index].status]} size="small"
                                  style={{marginRight: '0.5rem'}}/>

                            <div style={textStyle}>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="subtitle2"
                                    color="text.primary"
                                    gutterBottom
                                >
                                    {filteredFeedbackList[index].title}
                                </Typography>
                            </div>
                        </Box>}
                        secondary={<React.Fragment>
                            {filteredFeedbackList[index].tags.length > 0 ? (filteredFeedbackList[index].tags.map((tag, index) => (
                                <Typography
                                    variant='button'
                                    color={orange[700]}
                                    style={{marginRight: '0.5rem'}} display='inline'>
                                    {tag}
                                </Typography>))) : (
                                <Typography variant='button' color="text.secondary" display='inline'>
                                    No Tags
                                </Typography>)}
                            <div style={textStyle}>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {filteredFeedbackList[index].content}
                                </Typography>
                            </div>
                        </React.Fragment>}
                    />
                </ListItemButton>
            </ListItem>)}
        </FixedSizeList>
    </Box>);
}
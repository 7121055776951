import * as React from 'react';
import {
    GridActionsCellItem,
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import api from 'services/axiosApi';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

import {StyledDataGrid} from "utils/StyledComponents";

export default function LanguageCRUD() {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [largestDisplayOrder, setLargestDisplayOrder] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    // Dialogs states for creating a new language
    const [newLanguageDialogOpen, setNewLanguageDialogOpen] = React.useState(false);
    const [newLabel, setNewLabel] = React.useState('');

    async function fetchData() {
        const response = await api.get('/languages');
        const data = response.data;
        setRows(data.sort((a, b) => a.display_order - b.display_order));
        setLargestDisplayOrder(data.reduce((max, p) => p.display_order > max ? p.display_order : max, data[0].display_order));
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => async () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        fetchData();
    };

    const handleDeleteClick = (id) => async () => {
        await api.delete(`/languages/${id}`);
        fetchData();
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });
    };

    const handleCreateLanguage = async () => {
        // Check if language already exists or language is empty
        if (newLabel === '') {
            alert('Language is empty!');
            return;
        }

        // to lower case
        setNewLabel(newLabel.toLowerCase());

        const languageExists = rows.find((language) => language.name === newLabel);
        if (languageExists) {
            alert('Language already exists!');
            return;
        }

        // Create language
        try {
            const response = await api.post('/languages', {
                label: newLabel,
                display_order: largestDisplayOrder + 1
            });

            if (response.status === 200) {
                setNewLanguageDialogOpen(false);
                setNewLabel('');
                setLargestDisplayOrder(largestDisplayOrder + 1);
                fetchData();
            } else {
                alert('An error occurred while creating the language');
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred while creating the language');
        }
    };

    const processRowUpdate = async (newRow) => {
        const reqId = newRow.id;
        const reqLabel = newRow.label.toLowerCase();
        const reqDisplayOrder = newRow.display_order;
        const updatedLanguage = { ...newRow, isNew: false };

        if (reqLabel === '') {
            alert('Language label is required');
            return;
        }
        // check if language already exists
        const languageExists = rows.find((language) => language.label === reqLabel && language.id !== reqId);
        if (languageExists) {
            alert('Language already exists!');
            return;
        }

        // check if display_order is empty or already used
        if (reqDisplayOrder === '') {
            alert('Display Order is empty!');
            return;
        }
        const displayOrderExists = rows.find((language) => language.display_order === reqDisplayOrder && language.id !== reqId);
        if (displayOrderExists) {
            alert('Display Order already exists!');
            return;
        }

        try {
            const response = await api.put(`/languages/${reqId}`, {
                label: reqLabel,
                display_order: reqDisplayOrder
            });

            if (response.status === 200) {
                fetchData();
            } else {
                alert('An error occurred while updating the language');
            }
        } catch (error) {
            console.error('An error occurred while updating the language:', error);
            alert('An error occurred while updating the language');
        }

        return updatedLanguage;
    };

    const columns = [
        {
            field: 'label',
            headerName: 'Language',
            width: 200,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label='Save'
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label='Cancel'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label='Edit'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />
                ];
            }
        }
    ];

    // The toolbar for the data grid
    function EditToolbar() {
        return (
            <GridToolbarContainer>
                <Button color='primary' variant='contained' size='small' startIcon={<AddIcon />} onClick={() => setNewLanguageDialogOpen(true)}>
                    New Language
                </Button>
                <Chip
                    color='warning'
                    size='small'
                    label='Deleting (or updating) a language will result in the deletion (or update) of this language across all
                    existing terms.'
                />
            </GridToolbarContainer>
        );
    }

    const handleRowOrderChange = async (params) => {
        setIsLoading(true);

        const language1 = rows[params.oldIndex];
        const language2 = rows[params.targetIndex];

        // if language1 or language2 is undefined or the same, do nothing
        if (!language1 || !language2 || language1.id === language2.id) {
            setIsLoading(false);
            return;
        }
        console.log('Swapping display_order of:', language1, language2);

        // Swap the display_order of these two languages
        const tempOrder = language1.display_order;
        language1.display_order = language2.display_order;
        language2.display_order = tempOrder;

        try {
            // Update the first language's display_order
            await api.put(`/languages/${language1.id}`, {
                label: language1.label,
                display_order: language1.display_order
            });
            console.log('Updated language1:', language1)

            // Update the second language's display_order
            await api.put(`/languages/${language2.id}`, {
                label: language2.label,
                display_order: language2.display_order
            });
            console.log('Updated language2:', language2)
        } catch (error) {
            console.error('An error occurred while swapping display_order:', error);
            alert('An error occurred while swapping display_order');
        }

        fetchData();
        setIsLoading(false);
    };

    return (<div style={{height: 400, width: '100%'}}>
        <StyledDataGrid
            {...{rows, columns}}
            rows={rows}
            loading={isLoading}
            rowReordering
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: EditToolbar }}
            onRowOrderChange={handleRowOrderChange}
        />
        <Dialog open={newLanguageDialogOpen} onClose={() => setNewLanguageDialogOpen(false)}>
            <DialogTitle>Create New Language</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    label='Language'
                    type='text'
                    fullWidth
                    value={newLabel}
                    onChange={(e) => setNewLabel(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setNewLanguageDialogOpen(false)}>Cancel</Button>
                <Button onClick={handleCreateLanguage}>Create Language</Button>
            </DialogActions>
        </Dialog>
    </div>);
}

import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import api from 'services/axiosApi';
import {useCSVReader} from 'react-papaparse';


// MUI and MUI X Components
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    LinearProgress,
    Switch,
    TextField,
    Typography
} from '@mui/material';

import {
    GridActionsCellItem,
    GridRowModes,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    useGridApiRef
} from '@mui/x-data-grid-pro';

// MUI Icons
import {UploadFile} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import {StyledDataGrid} from "utils/StyledComponents";

import {capitalizeFirstLetter} from "utils/Utils";

const TermCRUD = (props) => {
    // From props
    const {setOpenFeedbackDialog, setOpenTermDialog, setSelectedTermId, setSelectedTerm} = props;

    // Data grid API
    const apiRef = useGridApiRef();

    // Component states
    const [isLoading, setIsLoading] = useState(true);

    // Data states
    const [languages, setLanguages] = useState([]);
    const [tags, setTags] = useState([]);

    // Data grid states
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [selectedTags, setSelectedTags] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    // CSV import states
    const {CSVReader} = useCSVReader();
    const [failedImportRecords, setFailedImportRecords] = useState([]);
    const [isImporting, setIsImporting] = useState(false);
    const [importProgress, setImportProgress] = useState(0);
    const [openImportDialog, setOpenImportDialog] = useState(false);

    // user
    const userRole = Cookies.get('userRole');
    let userTagAccess;

    if (userRole === 'admin') {
        userTagAccess = tags.map(tag => tag.id);
    } else if (userRole === 'editor') {
        try {
            userTagAccess = Cookies.get('userTagAccess') ? JSON.parse(Cookies.get('userTagAccess')) : [];
        } catch (error) {
            userTagAccess = [];
        }
    } else {
        userTagAccess = [];
    }

    const getTagLabels = (tagIds) => {
        return tagIds.map(tagId => tags.find(tag => tag.id === tagId)?.label || '')
    };


    async function fetchData() {
        setIsLoading(true);
        try {
            // Fetch terms, languages, and tags
            const [termsRes, languagesRes, tagsRes] = await Promise.all([api.get('/terms'), api.get('/languages'), api.get('/tags'),]);

            // Set the fetched data to state
            setLanguages(languagesRes.data.sort((a, b) => a.display_order - b.display_order));
            setTags(tagsRes.data);

            // Directly map and filter the fetched terms
            const newRows = termsRes.data.map(term => {
                const row = {
                    id: term.id,
                    note: term.note,
                    translations: term.translations,
                    hidden: term.hidden,
                    tags: Array.isArray(term.tags) ? term.tags.map(tag => tagsRes.data.find(t => t.id === tag.tag_id)?.id) : []
                };

                term.translations.forEach(translation => {
                    row[`lang_${translation.language_id}`] = translation.translation;
                });

                return row;
            })

            console.log(languages);
            setRows(newRows);  // Set the newly filtered rows
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    // Edit toolbar
    function EditToolbar() {
        const handleAddClick = async () => {
            setIsEditing(true);
            let newTerm = {
                tags: [], translations: [], notes: null,
            }

            // send an empty row to the backend to get an id
            const response = await api.post('/terms', newTerm);
            const id = response.data.id;
            newTerm.id = id;
            setRows((oldRows) => [{...newTerm, isNew: true}, ...oldRows]);
            setRowModesModel((oldModel) => ({
                ...oldModel, [id]: {mode: GridRowModes.Edit}
            }));
        }

        const handleCSVImport = async (result) => {
            setFailedImportRecords([]);
            const processedCSVRecords = [];

            // header columns
            const headerColumns = result.data[0];

            // to lower case and validate header columns
            for (let i = 0; i < headerColumns.length; i++) {
                headerColumns[i] = headerColumns[i].toLowerCase();
                // if the header column is not match any of the language or note or tags, abort it
                if (headerColumns[i] !== 'id' && headerColumns[i] !== 'note' && headerColumns[i] !== 'tags' && !languages.some(lang => lang.label.toLowerCase() === headerColumns[i])) {
                    alert("Language: " + headerColumns[i] + " is not supported, please check your CSV file header.");
                    return;
                }
            }

            // start validating and importing data row by row
            setIsImporting(true);
            setImportProgress(0);
            setOpenImportDialog(true);
            for (let i = 1; i < result.data.length; i++) {
                const row = result.data[i];

                let termData = {};
                let languageData = [];

                // language translations
                languages.forEach(lang => {
                    const index = headerColumns.indexOf(lang.label.toLowerCase());
                    if (index !== -1) {
                        const translation = row[index];
                        if (translation !== '' && translation !== undefined) {
                            languageData.push({language_id: lang.id, translation: translation});
                        }
                    }
                });

                // tagsData: label, not id
                const tagsData = row[headerColumns.indexOf('tags')] ? row[headerColumns.indexOf('tags')].split(',').map(tag => tag.trim()) : [];
                const noteData = row[headerColumns.indexOf('note')] ? row[headerColumns.indexOf('note')] : null;
                termData.tags = tagsData; // stores label!
                termData.note = noteData;
                termData.translations = languageData;
                termData.id = i;

                // Skip the row if language data is empty
                if (languageData.length === 0) {
                    termData.error = 'No language data';
                    setFailedImportRecords((oldRecords) => [...oldRecords, termData]);
                    continue;
                }

                // Check if the term is duplicate in the database
                const duplicateTerm = rows.find(row => row.note === termData.note && row.translations.length === termData.translations.length && row.translations.every((translation, index) => translation.language_id === termData.translations[index].language_id && translation.translation === termData.translations[index].translation));
                if (duplicateTerm) {
                    termData.error = 'Duplicate term in database';
                    setFailedImportRecords((oldRecords) => [...oldRecords, termData]);
                    continue;
                }

                // Check if user has access to all tags
                const hasAccess = termData.tags.every(tagLabel => userTagAccess.includes(tags.find(tag => tag.label === tagLabel)?.id));
                if (!hasAccess) {
                    termData.error = 'Not all tags are accessible';
                    setFailedImportRecords((oldRecords) => [...oldRecords, termData]);
                    continue;
                }

                // Check if the term is duplicate in the CSV
                const duplicateCSVRecord = processedCSVRecords.find(record => record.note === termData.note && record.translations.length === termData.translations.length && record.translations.every((translation, index) => translation.language_id === termData.translations[index].language_id && translation.translation === termData.translations[index].translation));
                if (duplicateCSVRecord) {
                    termData.error = 'Duplicate term in your CSV';
                    setFailedImportRecords((oldRecords) => [...oldRecords, termData]);
                    continue;
                }


                // Send the term to the backend
                try {
                    termData.tags = termData.tags.map(tagLabel => tags.find(tag => tag.label === tagLabel)?.id);
                    await api.post('/terms', termData);
                    // Add the term to the list of processed CSV records
                    processedCSVRecords.push(termData);
                } catch (error) {
                    termData.error = 'Backend reported an error';
                    setFailedImportRecords((oldRecords) => [...oldRecords, termData]);
                    console.log(error);
                }

                // Update the import progress, in percent
                setImportProgress(i / result.data.length * 100);
            }

            setIsImporting(false);
            // Fetch data again
            await fetchData();
        }

        return (<GridToolbarContainer style={{marginBottom: '1rem'}}>
            <GridToolbarQuickFilter style={{width: '100%', margin: '0.5rem'}}/>
            {(userRole === 'admin' || userRole === 'editor') && <>
                <Button color='primary' variant='contained' size='small' disabled={isEditing} startIcon={<AddIcon/>}
                        onClick={handleAddClick}>
                    Add record
                </Button>
                <CSVReader
                    onUploadAccepted={handleCSVImport}
                >
                    {({getRootProps}) => (<Button color='primary' variant='contained' size='small' disabled={isEditing}
                                                  startIcon={<UploadFile/>} {...getRootProps()}>
                        Import CSV
                    </Button>)}
                </CSVReader>
            </>}
            <GridToolbarExport/>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
        </GridToolbarContainer>);
    }

    // CRUD functions
    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setIsEditing(true);
        const currentRowTags = rows.find(row => row.id === id).tags;
        console.log(currentRowTags);
        setSelectedTags(currentRowTags);
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        setIsEditing(false);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
            handleDeleteClick(id)();
        }

        setIsEditing(false);
    };

    const handleDeleteClick = (id) => async () => {
        setIsEditing(true);
        await api.delete(`/terms/${id}`);
        setRows(rows.filter((row) => row.id !== id));
        setIsEditing(false);
    };

    const handleFeedbackClick = (id) => () => {
        setSelectedTermId(id);
        setOpenFeedbackDialog(true);
    }

    // an example for the backend:
    // update an existing term, /terms/:id
    // add a new term, /terms
    // request body:
    // {
    //     "note": "This is a new term",
    //     "translations": [
    //     {
    //         "language_id": 1,
    //         "translation": "New term translation for language 1"
    //     },
    //     {
    //         "language_id": 2,
    //         "translation": "New term translation for language 2"
    //     }
    // ],
    //     "tags": [1, 2, 3]
    // }
    const processRowUpdate = async (newRow) => {
        console.log(newRow);
        // if every language is empty or undefined, gives an alert
        if (languages.every(lang => newRow[`lang_${lang.id}`] === '' || newRow[`lang_${lang.id}`] === undefined)) {
            alert("Please enter at least one translation");
            return;
        }

        const reqBody = {
            note: newRow.note, // from all 'lang_1', 'lang_2', ... 'lang_n' to translations [{1, 'value'}, {2, 'value'}, ... {n, 'value'}]
            translations: languages.map(lang => ({
                language_id: lang.id, translation: newRow[`lang_${lang.id}`]
            })).filter(translation => translation.translation !== '' && translation.translation !== undefined),
            tags: selectedTags
        }

        setIsLoading(true);
        await api.put(`/terms/${newRow.id}`, reqBody).then(_ => {

        }).catch(error => {
            alert(error);
        });

        await fetchData();
        setIsLoading(false);

        newRow.tags = selectedTags;
        return newRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleTagChange = (event, newValue) => {
        const fixedTags = tags.filter(tag => !userTagAccess.includes(tag.id) && selectedTags.includes(tag.id));

        if (newValue.length === 0) {
            // Retain fixed tags when newValue is empty
            setSelectedTags(fixedTags.map(tag => tag.id));
            return;
        }

        const accessibleTags = newValue.filter(option => userTagAccess.includes(option.id));
        setSelectedTags([...fixedTags.map(tag => tag.id),  // Retain fixed tags
            ...accessibleTags.map(tag => tag.id),]);
    };


    function handleCardClick(id) {
        let rowTerm = rows.find(row => row.id === id);
        let term = {
            id: rowTerm.id, note: rowTerm.note, // tags: use the label, not id
            tags: rowTerm.tags.map(tagId => tags.find(tag => tag.id === tagId)?.label), // translations: use language label, not id
            translations: rowTerm.translations.map(translation => ({
                label: languages.find(lang => lang.id === translation.language_id)?.label,
                translation: translation.translation
            }))
        };
        setSelectedTerm(term);
        setOpenTermDialog(true);
    }

    const handleHiddenChange = (id) => async () => {
        console.log(id);
        setIsEditing(true);
        try {
            await api.put(`/terms/hide/${id}`);

            // Update the local state to reflect the change in the frontend
            setRows((prevTerms) => {
                return prevTerms.map((term) => {
                    if (term.id === id) {
                        return {
                            ...term, hidden: !term.hidden // Toggle the hidden status
                        };
                    }
                    return term;
                });
            });
        } catch (error) {
            console.error("Error updating the hidden status:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const columns = [{
        field: 'card',
        headerName: 'Open Card',
        width: 100,
        type: 'actions',
        cellClassName: 'actions',
        getActions: ({id}) => {
            return [<GridActionsCellItem
                icon={<SlowMotionVideoIcon/>}
                label="Card"
                onClick={() => handleCardClick(id)}
                color="success"
            />,];
        },
    }, {
        field: 'hidden', headerName: 'Hidden', renderCell: (params) => (<Switch
                checked={params.value}
                onChange={handleHiddenChange(params.id)}
                color="warning"
                inputProps={{'aria-label': 'controlled'}}
            />)
    }, {
        field: 'id', headerName: 'ID', width: 100, editable: false
    }, ...languages.map(lang => ({
        field: `lang_${lang.id}`, headerName: capitalizeFirstLetter(lang.label), minWidth: 200, editable: true,
    })), {field: 'note', headerName: 'Note', minWidth: 200, editable: true}, {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 200,
        editable: true,
        valueGetter: (params) => getTagLabels(params.value || []),
        renderCell: (params) => {
            const tagLabels = params.value || [];
            return (<>
                {tagLabels.map(tagLabel => {
                    const tag = tags.find(t => t.label === tagLabel);
                    return (<Chip
                        key={tag.label}
                        color={userRole === 'admin' || userTagAccess.includes(tag.id) ? 'info' : 'default'}
                        label={tag.label}
                        size="small"
                        style={{margin: '2px'}}
                    />);
                })}
            </>);
        },
        renderEditCell: () => {
            // Fixed tags that the user cannot change
            const fixedTags = tags.filter(tag => !userTagAccess.includes(tag.id) && selectedTags.includes(tag.id));

            // Accessible tags that the user can change
            const accessibleTags = tags.filter(tag => selectedTags.includes(tag.id) && userTagAccess.includes(tag.id));

            // Combined unique tags for this row
            const uniqueTags = [...new Set([...fixedTags, ...accessibleTags])];

            return (<Autocomplete
                multiple
                id="tags-checkbox"
                options={tags.filter(tag => userTagAccess.includes(tag.id))}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                value={uniqueTags}  // Use unique tags here
                onChange={handleTagChange}
                renderOption={(props, option, {selected}) => (<li {...props}>
                    <Checkbox
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.label}
                </li>)}
                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (<Chip
                    label={option.label}
                    {...getTagProps({index})}
                    disabled={!userTagAccess.includes(option.id)}
                />))}
                renderInput={(params) => (<TextField
                    {...params}
                    variant="standard"
                    placeholder="Tags"
                    sx={{width: 250}}
                />)}
            />);
        },
    }, {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 150,
        cellClassName: 'actions',
        getActions: ({id}) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [<GridActionsCellItem
                    icon={<SaveIcon/>}
                    label="Save"
                    color='primary'
                    onClick={handleSaveClick(id)}
                />, <GridActionsCellItem
                    icon={<CancelIcon/>}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                />,];
            }

            const returnValue = [];
            const visitorIconButtons = [<GridActionsCellItem
                icon={<FeedbackIcon/>}
                label="Feedback"
                onClick={handleFeedbackClick(id)}
                color="warning"
                disabled={!userRole || isEditing}
            />,];

            // Check if the user is an admin
            const isAdmin = userRole === 'admin';

            // Check if the user is an editor
            const isEditor = userRole === 'editor';

            // Find the row that matches the term ID
            const termRow = rows.find(row => row.id === id);

            // Check if the term has no tags
            const termHasNoTags = termRow.tags.length === 0;

            // Check if the user has access to at least one of the term's tags
            const userHasTagAccess = termRow.tags.some(tagId => userTagAccess.includes(tagId));

            // User has access if they are an admin, or they are an editor with either no tags on the term or access to at least one tag
            const hasAccess = isAdmin || (isEditor && (termHasNoTags || userHasTagAccess));

            if (hasAccess) {
                // User has access to perform the operation
            }


            const contributorIconButtons = [<GridActionsCellItem
                icon={<EditIcon/>}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="primary"
                disabled={!hasAccess || isEditing}
            />, <GridActionsCellItem
                icon={<DeleteIcon/>}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="error"
                disabled={!hasAccess || isEditing}
            />,];

            returnValue.push(...visitorIconButtons);
            returnValue.push(...contributorIconButtons);


            return returnValue;
        },
    },];


    return (<div>
        <StyledDataGrid
            style={{height: 800, width: '100%'}}
            columns={columns}
            rows={rows}
            apiRef={apiRef}
            loading={isLoading}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
                toolbar: EditToolbar, loadingOverlay: LinearProgress,
            }}
            initialState={{pinnedColumns: {left: ['card'], right: ['actions']}}}
        />
        <Dialog
            open={openImportDialog}
            onClose={() => setOpenImportDialog(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>Importing CSV</DialogTitle>
            <DialogContent>
                <LinearProgress variant="determinate" value={importProgress}/>
                <Typography variant="body1">Please wait while the CSV is being imported.</Typography>
                <Divider style={{marginTop: '1rem'}}/>
                <Typography variant="body1">
                    Successfully processed <b>{Math.round(importProgress)}%</b> of the CSV. Your tag access
                    is: <b>{userRole === "admin" ? 'admin' : userTagAccess.map(tagId => tags.find(tag => tag.id === tagId)?.label).join(', ')}</b>.
                </Typography>
                {failedImportRecords.length > 0 && <>
                    <Alert severity='error'>Failed to import <b>{failedImportRecords.length}</b> records:</Alert>
                    <StyledDataGrid
                        style={{height: 400}}
                        columns={[{field: 'id', headerName: 'ID', minWidth: 100}, {
                            field: 'translations',
                            headerName: 'Translations',
                            minWidth: 300,
                            valueGetter: (params) => params.value.map(translation => `${translation.translation}`).join(', '),
                        }, {
                            field: 'tags',
                            headerName: 'Tags',
                            minWidth: 100,
                            valueGetter: (params) => params.value.join(', ')
                        }, {
                            field: 'error', headerName: 'Error', minWidth: 300,
                        },]}
                        rows={failedImportRecords}
                    />
                </>}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenImportDialog(false)}
                    style={{marginTop: '1rem', float: 'right'}}
                    disabled={isImporting}
                >
                    {isImporting ? 'Importing...' : 'Close'}
                </Button>
            </DialogContent>
        </Dialog>
    </div>);
};

export default TermCRUD;

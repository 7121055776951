import React, { useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'services/firebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import Alert from "@mui/material/Alert";

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { state } = useLocation();
    const [successMessage, setSuccessMessage] = useState(state?.email ? 'Signup Successful' : null);


    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                user.getIdToken().then(token => {
                    const decodedToken = jwtDecode(token);
                    const role = decodedToken.role;
                    const tag_access = decodedToken.tag_access;
                    Cookies.set('userEmail', user.email);
                    Cookies.set('userUid', user.uid);
                    Cookies.set('userToken', token);
                    Cookies.set('userRole', role);
                    Cookies.set('userTagAccess', JSON.stringify(tag_access));
                    navigate('/');
                });
            })
            .catch((error) => {
                setError(error.message);
            });
    };

    return (
        <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
            <Paper style={{ padding: '1rem' }}>
                <Typography variant="h4" align="center">
                    Login
                </Typography>
                {successMessage && (
                    <Alert severity="success">
                        {successMessage}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Login
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => navigate('/signup')}
                                fullWidth
                            >
                                Go to Signup
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => navigate('/reset-password', { state: { email } })}
                                fullWidth
                            >
                                Forgot Password?
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => navigate('/')}
                                fullWidth
                            >
                                Go To Homepage
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};

export default LoginPage;

import React from 'react';
import TagCRUD from "components/CRUD/TagCRUD";
import LanguageCRUD from "components/CRUD/LanguageCRUD";
import {Grid, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";

const HomePage = () => {
    return (
        <Grid style={{ margin: '2rem' }}  >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Table Management</Typography>
                    <Divider style={{marginTop: '1rem'}}/>
                </Grid>
                <Grid item xs>
                    <Paper>
                        <TagCRUD/>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper>
                        <LanguageCRUD />
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HomePage;

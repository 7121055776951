import React, {useEffect, useState} from 'react';
import FeedbackList from 'components/Feedback/FeedbackList';
import FeedbackDetail from "components/Feedback/FeedbackDetail";
import {Box, Grid, Paper, Checkbox, FormControlLabel, TextField, LinearProgress, Switch} from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import api from "services/axiosApi";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FeedbackPostDialog from "../../components/Feedback/FeedbackPostDialog";

const FeedbackPage = () => {
    const userUid = Cookies.get('userUid');
    const userRole = Cookies.get('userRole');
    const userTagAccess = Cookies.get('userTagAccess');

    const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);
    const [feedbackList, setFeedbackList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState({
        open: true,
        solved: true,
        closed: true,
    });
    const [isOnlyDisplayMyFeedback, setIsOnlyDisplayMyFeedback] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isLoggedIn = userUid !== undefined;

    // Dialog states
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);


    async function fetchData() {
        setIsLoading(true);
        const response = await api.get('/feedback');
        // first get the feedback posted by the user himself, then sort by status (open > solved > closed), then by created_at
        response.data.sort((a, b) => {
            if (a.user_id === userUid && b.user_id !== userUid) {
                return -1;
            }
            if (a.user_id !== userUid && b.user_id === userUid) {
                return 1;
            }
            if (a.status === 'open' && b.status !== 'open') {
                return -1;
            }
            if (a.status !== 'open' && b.status === 'open') {
                return 1;
            }
            if (a.status === 'solved' && b.status === 'closed') {
                return -1;
            }
            if (a.status === 'closed' && b.status === 'solved') {
                return 1;
            }
            return new Date(b.created_at) - new Date(a.created_at);
        }
        );
        setFeedbackList(response.data);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    function postGeneralFeedback() {
        setOpenFeedbackDialog(true);
        fetchData();
    }

    return (<Box style={{margin: '2rem'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Feedback</Typography>
                    <Divider style={{marginTop: '1rem'}}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                            label="Search"
                            variant="standard"
                            value={searchText}
                            style={{width: '100%'}}
                            onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={statusFilter.open} onChange={() => setStatusFilter({...statusFilter, open: !statusFilter.open})} />}
                            label="Open"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={statusFilter.solved} onChange={() => setStatusFilter({...statusFilter, solved: !statusFilter.solved})} />}
                            label="Solved"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={statusFilter.closed} onChange={() => setStatusFilter({...statusFilter, closed: !statusFilter.closed})} />}
                            label="Closed"
                        />
                        <FormControlLabel
                            control={<Switch checked={isOnlyDisplayMyFeedback} onChange={() => setIsOnlyDisplayMyFeedback(!isOnlyDisplayMyFeedback)} />}
                            label="Display only my feedback"
                            disabled={!isLoggedIn}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={postGeneralFeedback}
                        startIcon={<FeedbackIcon />}
                        style={{marginTop: '1rem', marginBottom: '1rem'}}
                        disabled={!isLoggedIn}
                    >
                        Post General Feedback
                    </Button>
                    <Paper style={{marginTop: '0.5rem'}}>
                        <LinearProgress style={{visibility: isLoading ? 'visible' : 'hidden'}}/>
                        <FeedbackList
                            setSelectedFeedbackId={setSelectedFeedbackId}
                            feedbackList={feedbackList}
                            setFeedbackList={setFeedbackList}
                            searchText={searchText}
                            statusFilter={statusFilter}
                            isOnlyDisplayMyFeedback={isOnlyDisplayMyFeedback}
                            fetchData={fetchData}
                            userUid={userUid}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Box style={{marginTop: '0.5rem'}}>
                        <FeedbackDetail
                            selectedFeedbackId={selectedFeedbackId}
                            feedbackList={feedbackList}
                            fetchData={fetchData}
                            userUid={userUid}
                            userRole={userRole}
                            userTagAccess={userTagAccess}
                        />
                    </Box>
                </Grid>
            </Grid>
        <FeedbackPostDialog isOpen={openFeedbackDialog} setIsOpen={setOpenFeedbackDialog}
                            selectedTermId={null}/>
        </Box>);
};

export default FeedbackPage;

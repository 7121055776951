import React, { useState } from 'react';
import { Button, Grid, Paper, TextField, Typography, LinearProgress } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'services/firebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.email || '');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (isValidEmail(email)) {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    setSuccessMessage(`Password reset email sent to ${email}`);
                })
                .catch((error) => {
                    setError(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setError('Invalid email format');
            setIsLoading(false);
        }
    };

    return (
        <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
            {isLoading && <LinearProgress />}
            <Paper style={{ padding: '2rem' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                            Reset Password
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {successMessage ? (
                            <Alert severity="info">{successMessage}</Alert>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!isValidEmail(email) && email !== ''}
                                    helperText={!isValidEmail(email) && email !== '' ? 'Invalid email format' : ''}
                                    disabled={isLoading}
                                />
                                {error && <Typography variant="body2" color="error">{error}</Typography>}
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>
                                            Reset Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigate('/login')}
                            fullWidth
                            disabled={isLoading}
                        >
                            Back to Login
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ResetPasswordPage;

import * as React from 'react';

import api from 'services/axiosApi';

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    GridActionsCellItem,
    GridRowModes,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import {StyledDataGrid} from "utils/StyledComponents";

export default function UserManagementCRUD() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [tags, setTags] = React.useState([]);
    const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
    const [userChangingPassword, setUserChangingPassword] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState('');
    const [newUserDialogOpen, setNewUserDialogOpen] = React.useState(false);
    const [newUserEmail, setNewUserEmail] = React.useState('');
    const [newUserPassword, setNewUserPassword] = React.useState('');
    const [newUserRole, setNewUserRole] = React.useState('');
    const [newUserTagAccess, setNewUserTagAccess] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState({
        items: []
    });

    const rowsWithNestedProperties = Array.isArray(rows) ? rows.map(row => {
        return ({
            ...row,
            role: row.customClaims ? row.customClaims.role : null,
            tag_access: row.customClaims ? row.customClaims.tag_access : []
        });
    }) : [];

    const validatePassword = (password) => {
        if (password.length < 8) {
            alert('Password must be at least 8 characters long.');
            return false;
        }
        return true;
    };

    // User management functions have been grouped together for better readability
    const handlePasswordChangeClick = (id) => () => {
        setUserChangingPassword(rows.find((row) => row.uid === id));
        setPasswordDialogOpen(true);
    };

    const handlePasswordChange = async () => {
        if (!validatePassword(newPassword)) {
            return;
        }

        setIsLoading(true);
        try {
            const response = await api.put(`/users/${userChangingPassword.uid}`, {
                password: newPassword
            });

            if (response.status === 200) {
                console.log('Password changed successfully');
            } else {
                alert('An error occurred while changing the password');
            }
        } catch (error) {
            console.error('An error occurred while changing the password:', error);
            alert('An error occurred while changing the password');
        }

        setPasswordDialogOpen(false);
        setNewPassword('');

        setIsLoading(false);
    };

    const handleCreateUser = async () => {
        if (!validatePassword(newUserPassword)) {
            alert('Password must be at least 6 characters long.');
            return;
        }

        if (newUserEmail === '') {
            alert('Email address is required');
            return;
        }

        if (newUserRole === '') {
            alert('Role is required');
            return;
        }

        const error_msg = 'An error occurred while creating the user, check if the email is already in use.';

        setIsLoading(true);
        try {
            console.log('Creating user with email:', newUserEmail, 'password:', newUserPassword, 'role:', newUserRole);
            const response = await api.post('/users', {
                email: newUserEmail, password: newUserPassword, role: newUserRole, tag_access: newUserTagAccess
            });

            if (response.status === 200) {
                setNewUserDialogOpen(false);
                setNewUserEmail('');
                setNewUserPassword('');
                setNewUserRole('');
                setNewUserTagAccess([]);
                fetchData();
            } else {
                alert(error_msg);
            }
        } catch (error) {
            console.error(error_msg, error);
            alert(error_msg);
        }
        setIsLoading(false);
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => async () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        fetchData();
    };

    const handleDeleteClick = (id) => async () => {
        await api.delete(`/users/${id}`);
        fetchData();
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });
    };

    const processRowUpdate = async (newRow) => {
        if (newRow.email === '') {
            alert('Email address is required');
            return;
        }

        // if set the role to visitor, remove all tag access
        if (newRow.role === 'visitor') {
            newRow.tag_access = [];
        }

        try {
            const response = await api.put(`/users/${newRow.uid}`, {
                email: newRow.email, role: newRow.role, tag_access: newRow.tag_access
            });

            if (response.status === 200) {
                fetchData();
            } else {
                alert('An error occurred while updating the user');
            }
        } catch (error) {
            console.error('An error occurred while updating the user:', error);
            alert('An error occurred while updating the user');
        }

        return newRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    async function fetchData() {
        setIsLoading(true);
        const result = await api.get('/users');
        setRows(result.data);
        const result_tags = await api.get('/tags');
        setTags(result_tags.data);
        console.log('Tags:', result_tags.data);
        setIsLoading(false);
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    // Defining the columns of the data grid
    const columns = [{field: 'uid', headerName: 'UID', width: 300, editable: false}, {
        field: 'email',
        headerName: 'Email',
        width: 300,
        editable: true
    }, {
        field: 'role', headerName: 'Role', width: 100, editable: true, renderEditCell: (params) => {
            return (<Select
                    value={params.value || ''}
                    onChange={(event) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: event.target.value
                        }, event);
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    fullWidth
                >
                    <MenuItem value={'admin'}>admin</MenuItem>
                    <MenuItem value={'editor'}>editor</MenuItem>
                    <MenuItem value={'visitor'}>visitor</MenuItem>
                </Select>);
        }
    }, {
        field: 'tag_access', headerName: 'Tag Access', width: 500, editable: true, renderCell: (params) => {
            const tagIds = params.value || [];
            const tagChips = tags.filter(tag => tagIds.includes(tag.id));
            return (<Box>
                    {tagChips.map((tagChip) => (
                        <Chip key={tagChip.id} size='small' label={tagChip.label} style={{margin: 2}}/>))}
                </Box>);
        }, renderEditCell: (params) => {
            return (<Autocomplete
                    multiple
                    id="tags-standard"
                    options={tags}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    value={tags.filter(tag => params.value?.includes(tag.id))}
                    onChange={(event, newValue) => {
                        const newIds = newValue.map(tag => tag.id);
                        params.api.setEditCellValue({id: params.id, field: params.field, value: newIds}, event);
                    }}
                    renderOption={(props, option, {selected}) => (<li {...props}>
                            <Checkbox
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            {option.label}
                        </li>)}
                    renderInput={(params) => (<TextField
                            {...params}
                            sx={{width: 500}}
                            variant="standard"
                            size="small"
                            label="Tag Access"
                            placeholder="Select Tags"
                        />)}
                />);
        }
    }, {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 150,
        cellClassName: 'actions',
        getActions: ({id}) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [<GridActionsCellItem
                    icon={<SaveIcon/>}
                    label='Save'
                    onClick={handleSaveClick(id)}
                />, <GridActionsCellItem
                    icon={<CancelIcon/>}
                    label='Cancel'
                    className='textPrimary'
                    onClick={handleCancelClick(id)}
                    color='inherit'
                />];
            }

            return [<GridActionsCellItem
                icon={<EditIcon/>}
                label='Edit'
                className='textPrimary'
                onClick={handleEditClick(id)}
                color='inherit'
            />, <GridActionsCellItem
                icon={<VpnKeyIcon/>}
                label='Change Password'
                onClick={handlePasswordChangeClick(id)}
                color='inherit'
            />, <GridActionsCellItem
                icon={<DeleteIcon/>}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
            />];
        }
    }];

    // The toolbar for the data grid
    function EditToolbar() {
        const handleClickNewUser = async () => {
            setNewUserDialogOpen(true);
        };

        return (<GridToolbarContainer>
                <Button color='primary' variant='contained' size='small' startIcon={<AddIcon/>}
                        onClick={handleClickNewUser}>
                    Register New User
                </Button>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarExport/>
                <GridToolbarQuickFilter debounceMs={1000} style={{marginLeft: 'auto'}}/>
            </GridToolbarContainer>);
    }

    // The render method for the component
    return (<div>
            <StyledDataGrid
                sx={{
                    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
                        fontSize: '0.875rem'
                    }, '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid`, borderColor: 'grey.300'
                    }
                }}
                rows={rowsWithNestedProperties}
                columns={columns}
                getRowId={(row) => row.uid}
                editMode='row'
                rowModesModel={rowModesModel}
                filterModel={filterModel}
                loading={isLoading}
                onFilterModelChange={(model) => setFilterModel(model)}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar, loadingOverlay: LinearProgress
                }}
            />
            <Dialog
                open={passwordDialogOpen}
                onClose={() => setPasswordDialogOpen(false)}
            >
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a new password for the user {userChangingPassword?.email}.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='New Password'
                        type='password'
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPasswordDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handlePasswordChange}>Change Password</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={newUserDialogOpen} onClose={() => setNewUserDialogOpen(false)}>
                <DialogTitle>Create New User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Email'
                        type='email'
                        fullWidth
                        value={newUserEmail}
                        onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                    <TextField
                        margin='dense'
                        label='Password'
                        type='password'
                        fullWidth
                        value={newUserPassword}
                        onChange={(e) => setNewUserPassword(e.target.value)}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={4}>

                            <Select
                                value={newUserRole}
                                onChange={(event) => setNewUserRole(event.target.value)}
                                fullWidth
                            >
                                <MenuItem value={'admin'}>admin</MenuItem>
                                <MenuItem value={'editor'}>editor</MenuItem>
                                <MenuItem value={'visitor'}>visitor</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                multiple
                                id="new-user-tag-access"
                                options={tags}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.label}
                                value={tags.filter(tag => newUserTagAccess.includes(tag.id))}
                                onChange={(event, newValue) => {
                                    const newIds = newValue.map(tag => tag.id);
                                    setNewUserTagAccess(newIds);
                                }}
                                renderInput={(params) => (<TextField
                                        {...params}
                                        label="Tag Access"
                                        placeholder="Select Tags"
                                    />)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewUserDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateUser}>Create User</Button>
                </DialogActions>
            </Dialog>
        </div>);
}

import React, {useState} from 'react';
import TermCRUD from 'components/CRUD/TermCRUD';
import FeedbackPostDialog from "components/Feedback/FeedbackPostDialog";
import TermDialog from "components/Term/TermDialog";

import {Grid, Paper, Typography, Divider} from '@mui/material';

const TablePage = () => {
    // Dialog states
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [selectedTermId, setSelectedTermId] = useState(null);

    const [openTermDialog, setOpenTermDialog] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState(null);

    return (<Grid style={{margin: '2rem'}}>
        <Grid item xs={12}>
            <Typography variant="h4">Term Table</Typography>
            <Divider style={{marginTop: '1rem'}}/>
        </Grid>
        <Grid item xs={12}>
            <Paper>
                <TermCRUD setSelectedTermId={setSelectedTermId}
                          setOpenFeedbackDialog={setOpenFeedbackDialog}
                          setSelectedTerm={setSelectedTerm}
                          setOpenTermDialog={setOpenTermDialog}
                />
                <FeedbackPostDialog isOpen={openFeedbackDialog} setIsOpen={setOpenFeedbackDialog}
                                    selectedTermId={selectedTermId}/>
                <TermDialog isOpen={openTermDialog} setIsOpen={setOpenTermDialog} term={selectedTerm}/>
            </Paper>
        </Grid>
    </Grid>);
};

export default TablePage;

import React from 'react';
import {Stack, Button, Card, CardContent, Chip, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';

import FeedbackIcon from '@mui/icons-material/Feedback';

import FeedbackPostDialog from "components/Feedback/FeedbackPostDialog";
import {capitalizeFirstLetter} from "utils/Utils";

import Cookies from "js-cookie";

const TermCardsRender = (props) => {
    const {transformedTerms, hideButton} = props;

    // Dialog states
    const [openFeedbackDialog, setOpenFeedbackDialog] = React.useState(false);
    const [selectedTermId, setSelectedTermId] = React.useState(null);

    // if no results, display a message
    if (transformedTerms.length === 0) {
        return (<Typography variant="h6" color="textSecondary">
            No term matched.
        </Typography>);
    }

    return (<div>
        {transformedTerms.map((term, idx) => (<Card key={idx} style={{marginBottom: '1rem'}} elevation={3}>
            <CardContent>
                <Stack direction="row"
                       spacing={2}
                       display="flex"
                       alignItems="center"
                       style={{marginBottom: '0.5rem'}}
                >
                    <Typography variant="subtitle2" color="textSecondary">
                        Tags:
                    </Typography>
                    {term.tags.map((tag, tagIdx) => (
                        <Chip key={tagIdx} label={tag} style={{marginLeft: '0.5rem'}} color="primary" size="small"
                        />))}
                    {!hideButton && <Button size="small"
                                            color="primary"
                                            startIcon={<FeedbackIcon/>}
                                            variant="outlined"
                                            style={{marginLeft: 'auto'}}
                                            onClick={() => {
                                                // if not logged in, redirect to login page
                                                if (!Cookies.get('userUid')) {
                                                    window.location.href = '/login';
                                                    return;
                                                }

                                                setSelectedTermId(term.id);
                                                setOpenFeedbackDialog(true);
                                            }}
                    >
                        Post Feedback
                    </Button>}
                </Stack>

                <Typography variant="subtitle2" color="textSecondary">
                    Translations:
                </Typography>

                <Table>
                    <TableBody>
                        {term.translations.map((translation, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ width: '150px' }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {capitalizeFirstLetter(translation.label)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        <b>{translation.translation}</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {term.note && (<Typography variant="body2" color="textPrimary">
                    Note: {term.note}
                </Typography>)}

            </CardContent>
        </Card>))}
        <FeedbackPostDialog isOpen={openFeedbackDialog} setIsOpen={setOpenFeedbackDialog}
                            selectedTermId={selectedTermId}/>
    </div>);
}

export default TermCardsRender;

import React from 'react';
import UserCRUD from 'components/CRUD/UserCRUD';
import {Grid, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";

const UsersManagement = () => {

    return (
        <Grid style={{ margin: '2rem' }}>
            <Grid item xs={12}>
                <Typography variant="h4">User Management</Typography>
                <Divider style={{marginTop: '1rem'}}/>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <UserCRUD />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default UsersManagement;

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SearchPage from 'pages/CommonPages/SearchPage';
import TablePage from 'pages/EditorPages/TablePage';
import FeedbackPage from 'pages/CommonPages/FeedbackPage';
import AboutPage from 'pages/CommonPages/AboutPage';
import ManagementTablesPage from 'pages/AdminPages/TableManagement';
import ManagementUsersPage from 'pages/AdminPages/UsersManagement';
import LoginPage from "pages/AuthenticatePages/LoginPage";
import SignupPage from "pages/AuthenticatePages/SignupPage";
import ResetPasswordPage from "pages/AuthenticatePages/ResetPasswordPage";


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<SearchPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/about" element={<AboutPage />} />

            <Route path="/table" element={<TablePage />} />

            <Route path="/management/table" element={<ManagementTablesPage />} />
            <Route path="/management/users" element={<ManagementUsersPage />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
        </Routes>
    );
};

export default AppRoutes;

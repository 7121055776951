import * as React from 'react';
import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import {GridActionsCellItem, GridRowModes, GridToolbarContainer} from '@mui/x-data-grid-pro';
import api from 'services/axiosApi';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

import {StyledDataGrid} from "utils/StyledComponents";

export default function TagCRUD() {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [newTagDialogOpen, setNewTagDialogOpen] = React.useState(false);
    const [newTag, setNewTag] = React.useState('');
    const [filterModel, setFilterModel] = React.useState({
        items: []
    });

    const handleCreateTag = async () => {
        // check if tag already exists or tag is empty
        const tagExists = rows.find((tag) => tag.label === newTag);
        if (tagExists) {
            alert('Tag already exists!');
            return;
        }
        if (newTag === '') {
            alert('Tag is empty!');
            return;
        }

        // create tag
        try {
            const response = await api.post('/tags', {
                label: newTag
            });

            if (response.status === 200) {
                setNewTagDialogOpen(false);
                fetchData();
            } else {
                alert('An error occurred while creating the tag');
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred while creating the tag');
        }
    };


    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => async () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        fetchData();
    };

    const handleDeleteClick = (id) => async () => {
        await api.delete(`/tags/${id}`);
        setRows(rows.filter((row) => row.id !== id));

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        if (newRow.label === '') {
            alert('Tag label is required');
            return;
        }

        try {
            const response = await api.put(`/tags/${newRow.id}`, {
                label: newRow.label
            });

            if (response.status === 200) {
                fetchData();
            } else {
                alert('An error occurred while updating the tag');
            }
        } catch (error) {
            console.error('An error occurred while updating the tag:', error);
            alert('An error occurred while updating the tag');
        }

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    async function fetchData() {
        const result_tags = await api.get('/tags');
        setRows(result_tags.data);
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    const columns = [{field: 'id', headerName: 'ID', width: 100, editable: false}, {
        field: 'label',
        headerName: 'Label',
        width: 200,
        editable: true
    }, {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 150,
        cellClassName: 'actions',
        getActions: ({id}) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [<GridActionsCellItem
                    icon={<SaveIcon/>}
                    label='Save'
                    onClick={handleSaveClick(id)}
                />, <GridActionsCellItem
                    icon={<CancelIcon/>}
                    label='Cancel'
                    className='textPrimary'
                    onClick={handleCancelClick(id)}
                    color='inherit'
                />];
            }

            return [<GridActionsCellItem
                icon={<EditIcon/>}
                label='Edit'
                className='textPrimary'
                onClick={handleEditClick(id)}
                color='inherit'
            />, <GridActionsCellItem
                icon={<DeleteIcon/>}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
            />];
        }
    }];

    // The toolbar for the data grid
    function EditToolbar() {
        const handleClickNewTag = async () => {
            setNewTagDialogOpen(true);
        };

        return (<GridToolbarContainer>
                <Button color='primary' variant='contained' size='small' startIcon={<AddIcon/>}
                        onClick={handleClickNewTag}>
                    New Tag
                </Button>
                <Chip
                    color='warning'
                    size='small'
                    label='Deleting (or updating) a tag will result in the deletion (or update) of this tag across all
                    existing terms.'
                />
            </GridToolbarContainer>);
    }

    return (<div>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                editMode='row'
                rowModesModel={rowModesModel}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar
                }}
            />
            <Dialog open={newTagDialogOpen} onClose={() => setNewTagDialogOpen(false)}>
                <DialogTitle>Create New Tag</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Tag'
                        type='tag'
                        fullWidth
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewTagDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateTag}>Create Tag</Button>
                </DialogActions>
            </Dialog>
        </div>);
}
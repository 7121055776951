import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import MenuDrawer from './MenuDrawer';
import {Box} from "@mui/material";

const ExpandableAppBar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const userEmail = Cookies.get('userEmail'); // Get email from cookie, update according to your logic

    const toggleDrawer = (isOpen) => {
        setIsDrawerOpen(isOpen);
    };

    const handleLogout = () => {
        // Your logout logic here
        Cookies.remove('userEmail'); // Clear user email cookie
        Cookies.remove('userToken'); // Clear user token cookie
        Cookies.remove('userRole'); // Clear user role cookie
        Cookies.remove('userTagAccess'); // Clear user tag access cookie
        Cookies.remove('userUid'); // Clear user uid cookie
        navigate('/login'); // Redirect to login page
    };

    return (
        <Box>
            <AppBar position="static" color='info'>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Math Term Bank
                    </Typography>
                    {userEmail ? (
                        <>
                            <AccountCircleIcon />
                            <Typography variant="subtitle1" style={{ margin: '16px' }}>
                                {userEmail}
                            </Typography>
                            <Button color="inherit" variant="outlined" onClick={handleLogout}>
                                Logout
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" variant="outlined" style={{ margin: '16px' }}  onClick={() => navigate('/login')}>
                                Login
                            </Button>
                            <Button color="inherit" variant="outlined"  onClick={() => navigate('/signup')}>
                                Signup
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <MenuDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </Box>
    );
};

export default ExpandableAppBar;

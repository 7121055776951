import api from 'services/axiosApi';
import Cookies from 'js-cookie';

export async function getLanguages() {
    const response = await api.get('/languages');
    return response.data;
}

export async function getTags() {
    const response = await api.get('/tags');
    return response.data;
}

export async function getTerms() {
    const response = await api.get('/terms');
    return response.data;
}

export async function getTermById(id) {
    const response = await api.get('/terms/' + id);
    return response.data;
}

export function transformedTerm(term, languages, tags) {
    return {
        ...term,
        translations: term.translations.map(translation => {
            const language = languages.find(lang => lang.id === translation.language_id);
            return {
                label: language ? language.label : 'Unknown',
                translation: translation.translation
            };
        }),
        tags: term.tags.map(tag => {
            const tagObj = tags.find(t => t.id === tag.tag_id);
            return tagObj ? tagObj.label : 'Unknown';
        })
    };
}

export async function getTransformedTermById(id) {
    const term = await getTermById(id);
    const languages = await getLanguages();
    const tags = await getTags();
    return transformedTerm(term, languages, tags);
}

export function getUserInfo() {
    return {
        userUid: Cookies.get('userUid'),
        userEmail: Cookies.get('userEmail'),
        userRole: Cookies.get('userRole'),
        userTagAccess: Cookies.get('userTagAccess'),
    }
}
import React, {useState} from 'react';
import api from 'services/axiosApi';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Snackbar,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";

export default function FeedbackPostDialog(props) {
    const {selectedTermId, isOpen, setIsOpen} = props;

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    async function handlePost() {
        // validate
        if (title === '') {
            alert('Please enter a title.');
            return;
        }
        if (content === '') {
            alert('Please enter a description.');
            return;
        }

        setIsLoading(true);

        try {
            await api.post('/feedback', {
                title: title, content: content, term_id: selectedTermId,
            });
            setIsOpen(false);
            setIsSuccess(true);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    function handleCancel() {
        setIsOpen(false);
    }

    return (<div>
            <Dialog open={isOpen} onClose={props.onClose}>
                {selectedTermId ? <DialogTitle>Post Feedback for Term ID: {selectedTermId}</DialogTitle> :
                    <DialogTitle>Post Feedback</DialogTitle>}
                <DialogContent>
                    <DialogContentText>
                        Please provide a title and description for your feedback.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Title"
                        type="text"
                        fullWidth
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="content"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <DialogActions>
                        <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                            <LinearProgress style={{visibility: isLoading ? 'visible' : 'hidden'}}/>
                            <Button
                                onClick={handleCancel}
                                variant='text'
                                style={{marginRight: '1rem'}}
                                disabled={isLoading}
                                startIcon={<CancelIcon/>}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handlePost}
                                variant='contained'
                                disabled={isLoading}
                                startIcon={<SaveIcon/>}
                            >
                                Post
                            </Button>
                        </Box>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={() => setIsSuccess(false)} severity="success" sx={{width: '100%'}}>
                    Feedback posted successfully!
                </Alert>
            </Snackbar>
        </div>);
}